<template>
  <div class="wrapper">
    <div class="header" id="header">
      <div class="yxj-container header-content yxj-space">
        <div
          class="mobile-menu"
          v-if="$store.getters.mobile"
          @click.stop="$store.commit('CHANGE_OPENMENU', true)"
        ></div>
        <div class="logo-area box" @click="jumpTo"></div>
        <div
          class="yxj-center mobile-menu-wrapper"
          :class="{ active: $store.getters.openMenu }"
          @click="$store.commit('CHANGE_OPENMENU', false)"
          id="mask"
        >
          <div class="mask"></div>
          <div class="yxj-center mobile-flex" @click.stop>
            <div
              class="yxj-center mobile-close"
              v-if="$store.getters.mobile"
              @click.stop="$store.commit('CHANGE_OPENMENU', false)"
            ></div>
            <div class="yxj-center mobile-review" @touchmove.stop>
              <router-link
                tag="div"
                class="box"
                :class="activeTab === 'home' ? 'is-active' : ''"
                to="/home"
                >首页</router-link
              >
              <router-link
                tag="div"
                class="box"
                :class="activeTab === 'product' ? 'is-active' : ''"
                to="/product"
                >医生站</router-link
              >
              <router-link
                tag="div"
                class="box"
                :class="activeTab === 'download' ? 'is-active' : ''"
                to="/media"
                >新媒体</router-link
              >
              <router-link
                tag="div"
                class="box"
                :class="activeTab === 'about' ? 'is-active' : ''"
                to="/about"
                >历程</router-link
              >
              <router-link
                tag="div"
                class="box"
                :class="activeTab === 'join' ? 'is-active' : ''"
                to="/culture"
                >文化</router-link
              >
              <router-link
                tag="div"
                class="box"
                :class="activeTab === 'social' ? 'is-active' : ''"
                to="/social"
                >社会责任</router-link
              >
              <router-link
                tag="div"
                class="box"
                :class="activeTab === 'recruitment' ? 'is-active' : ''"
                to="/recruitment"
                >招聘</router-link
              >
              <router-link
                tag="div"
                class="right-border box"
                :class="activeTab === 'contact' ? 'is-active' : ''"
                to="/contact"
                >联系我们</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from '@/components/Foot'
import { mapState } from 'vuex'
import getOsInfo from "@/utils/getOsInfo";
export default {
  name: 'Wrapper',
  data() {
    return {
      activeTab: 'home',
      oldScroll: 0,
      openMenu: false,
      clientWidth: null,
      os: null
    }
  },
  components: {
    Foot
  },
  watch: {
    '$route'(to) {
      // 监听路由变化
      this.init(to.path)
      this.$store.commit('CHANGE_OPENMENU', false)
    },
  },
  created() {
    this.init(this.$route.path)
    this.getOs();
    if (this.clientWidth <= 1199) {
      this.$store.commit('CHANGE_MOBILE', true)
      this.onScroll()
    } else {
      this.$store.commit('CHANGE_MOBILE', false)
      this.$nextTick(() => {
        document.getElementById('header').style.transform = 'translateY(0px)'
        document.getElementById('mask').style.transform = 'translateY(0px)'
      })
    }
    window.onresize = () => {
      this.getOs();
      if (this.clientWidth <= 1199) {
        this.$store.commit('CHANGE_MOBILE', true)
        this.onScroll()
      } else {
        window.onscroll = ''
        this.$store.commit('CHANGE_MOBILE', false)
        this.$nextTick(() => {
          document.getElementById('header').style.transform = 'translateY(0px)'
          document.getElementById('mask').style.transform = 'translateY(0px)'
        })
      }
    }
  },
  computed: {
    ...mapState({
      mobile: state => state.user.mobile
    })
  },
  methods: {
    jumpTo() {
      // window.open('https://www.yxj.org.cn/', '_blank')
      window.location.href = 'https://www.yxj.org.cn/'
    },
    init(path) {
      if (path === '/home') {
        this.activeTab = 'home'
      }
      if (path === '/product') {
        this.activeTab = 'product'
      }
      if (path === '/media') {
        this.activeTab = 'download'
      }
      if (path === '/about') {
        this.activeTab = 'about'
      }
      if (path === '/culture') {
        this.activeTab = 'join'
      }
      if (path === '/social') {
        this.activeTab = 'social'
      }
      if (path === '/recruitment') {
        this.activeTab = 'recruitment'
      }
      if (path === '/contact') {
        this.activeTab = 'contact'
      }
    },
    onScroll() {
      window.onscroll = () => {
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 112) {
          document.getElementById('header').style.transform = 'translateY(-56px)'
          document.getElementById('mask').style.transform = 'translateY(56px)'
        }
        if (this.oldScroll > scrollTop) {
          document.getElementById('header').style.transform = 'translateY(0px)'
          document.getElementById('mask').style.transform = 'translateY(0px)'
        }
        this.oldScroll = scrollTop
      }
    },
    getOs() {
      this.os = new getOsInfo();
      this.clientWidth = this.os.version == 'Iphone' || this.os.version == 'Android' ? document.body.clientWidth : window.innerWidth
    }
  },
  beforeDestroy() {
    window.onresize = function () { };
    window.onscroll = function () { };
  }
}
</script>

<style lang="scss" scoped>
@import "./Wrapper.scss";
</style>
